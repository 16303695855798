import { GetParksApiParksGetApiResponse } from '@shared/api/services/common/api';
import { TelemetryHotStorageOutput } from '@shared/api/services/telemetry/api';

type Props = {
  telemetryData: TelemetryHotStorageOutput[];
  parks?: GetParksApiParksGetApiResponse;
  eParkId?: string;
};

export const getLngLat = ({
  telemetryData,
  parks,
  eParkId,
}: Props): { initialLatitude?: number; initialLongitude?: number } => {
  const currentElectricBusPark = parks?.find(
    ({ id }) => String(id) === String(eParkId)
  );
  const parkCoordinates = currentElectricBusPark?.coordinates?.split(', ');
  const parkLatitude =
    parkCoordinates === undefined
      ? parkCoordinates
      : Number(parkCoordinates[0]);
  const parkLongitude =
    parkCoordinates === undefined
      ? parkCoordinates
      : Number(parkCoordinates[1]);

  if (telemetryData === undefined || telemetryData.length === 0) {
    return {
      initialLatitude: parkLatitude,
      initialLongitude: parkLongitude,
    };
  }

  const firstEbusLatitude = telemetryData[0].latitude;
  const firstEbusLongitude = telemetryData[0].longitude;

  return {
    initialLatitude: firstEbusLatitude === 0 ? parkLatitude : firstEbusLatitude,
    initialLongitude:
      firstEbusLongitude === 0 ? parkLongitude : firstEbusLongitude,
  };
};
